<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">Banner管理</div>
		</div>
		<div class="flex">
			<div></div>
			<el-button @click="addshow=true;addtype=1;resource='1'">添加</el-button>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="bannerTitle" label="标题">
				</el-table-column>
				<el-table-column prop="clicknum" label="点击量">
				</el-table-column>
				<el-table-column prop="sort" label="序号">
				</el-table-column>
				<el-table-column prop="isShow" label="状态">
					<template slot-scope="scope">
						<div style="display: flex;justify-content: center;">
							<div style="display: flex;">
								<p v-if="scope.row.isShow==1">显示</p>
								<p v-else>隐藏</p>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="添加日期">
				</el-table-column>
				<el-table-column fixed="right" label="操作">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="updataclick(scope.$index, data)" type="text" size="small">
							修改
						</el-button>
						<el-button @click.native.prevent="deleteList(scope.$index, data)" type="text" size="small">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 新增的弹窗 -->
		<el-dialog :visible.sync="addshow" width="700px" :before-close="handleClose" :title="addtype == 1 ? '新增Banner' : '修改Banner'">
			<el-form class="form_box" :model="sizeForm" label-position='left' ref="sizeForm" size="small">
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>标题：</p>
							<el-input :maxlength="30" v-model="sizeForm.bannerTitle" clearable placeholder="请输入banner标题"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;">跳转类型：</p>
							<el-radio-group v-model="resource" @change="radioChange"  style="display:flex;align-items:center;">
                                <el-radio label="1">跳转小程序</el-radio>
                                <el-radio label="2">跳转外链</el-radio>
                            </el-radio-group>
						</div>
					</template>
				</el-form-item>
				<el-form-item  v-if="resource == '2'">
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>跳转链接：</p>
							<el-input type="text" v-model="sizeForm.jumpUrl" :maxlength="100" clearable placeholder="请输入跳转链接"></el-input>
						</div>
					</template>
				</el-form-item>
                <el-form-item v-if="resource == '1'">
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>指定地址：</p>
							<el-input type="text" v-model="sizeForm.indexUrl" :maxlength="100" clearable placeholder="请输入指定地址"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item v-if="resource == '1'">
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>APPID：</p>
							<el-input type="text" v-model="sizeForm.appId"  :maxlength="18" clearable placeholder="请输入APPID"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>序号：</p>
							<el-input type="number" min="1" v-model="sizeForm.sort" oninput="if(value.length>11)value=value.slice(0,11)"  @keyup.native="keyVerify($event)" clearable placeholder="请输入序号" @blur="sortBlur"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item prop='isShow'>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>状态：</p>
                            <el-select  v-model="sizeForm.isShow" placeholder="请选择显示状态">
                                <el-option v-for="item in roleIdsoption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                            </el-select>
						</div>
					</template>
				</el-form-item>
				<el-form-item prop="imgUrl">
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>图片：</p>
                            <el-upload class="avatar-uploader" action="api/web/tool/file/upload" accept=".jpg,.jpeg,.png" :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                <img v-if="sizeForm.imgUrl" :src="sizeForm.imgUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon photo"></i>
                            </el-upload>
						</div>
					</template>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:flex-end;margin-top:20px;">
					<el-button @click.native.prevent='handleClose'>取消</el-button>
					<el-button v-if="addtype==1" type="primary" @click.native.prevent="submitForm('sizeForm')">添加</el-button>
					<el-button v-if="addtype==2" type="primary" @click.native.prevent="onUpdata('sizeForm')">修改</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		bannerList,bannerAdd,bannerUpdate,bannerDelete
	} from "@/api/platform/index";
	export default {
		name: "bannerSettings",
		data() {
			return {
				resource: "1",
				roleIdsoption: [{label: '显示',value: 1},{label: '隐藏',value: 0}],
				headers: {
					Authorization: this.$store.state.user.Token
				},
				dialogVisible: false,
				data: [],
				addshow: false,
				sizeForm: {
					bannerTitle: '',
					imgUrl: '',
					isShow: 1,
					sort: 1,
					appId: '',
					indexUrl: '',
					jumpUrl: '',
				},
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				addtype: '',
				id: 0,
			};
		},
		mounted() {
			let that = this;
			that.list(); //banner-列表
		},
		created() {
		},
		methods: {
			//radio切换
			radioChange(e){
			
			},
			//获取列表
			list() {
				bannerList({
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},
			//分页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val;
				this.list();
			},
			// 序号验证
			sortBlur(){
				let that = this;
				if(Number(that.sizeForm.sort) >= 0){
					that.sizeForm.sort = that.sizeForm.sort;
				}else{
					that.$message.error('序号不能为负数');
				}
			},
			//编辑提交
			onUpdata(formName){
				if(this.addshow){
					this.$refs[formName].validate((valid) => {
						if (valid) {
							if(this.$refs[formName].model.bannerTitle == ''){
								this.$message.warning('banner标题不能为空');
							}else if(this.$refs[formName].model.imgUrl == ''){
								this.$message.warning('banner图片不能为空');
							}else if(this.resource == '1' && this.$refs[formName].model.appId == ''){
								this.$message.warning('请输入APPID');
							}else if(this.resource == '1' && this.$refs[formName].model.indexUrl == ''){
								this.$message.warning('请输入指定地址');
							}else if(this.resource == '2' && this.$refs[formName].model.jumpUrl == ''){
								this.$message.warning('请输入跳转链接');
							}else if(this.$refs[formName].model.sort.toString() == ''){
								this.$message.warning('序号不能为空');
							}else if(Number(this.$refs[formName].model.sort) < 0){
								this.$message.warning('序号不能为负数');
							}else{
								if(this.resource == '2'){
									this.$refs[formName].model.appId = '';
									this.$refs[formName].model.indexUrl = '';
								}else if(this.resource == '1'){
									this.$refs[formName].model.jumpUrl = ''
								}
								bannerUpdate({
									appId: this.$refs[formName].model.appId,
									bannerTitle: this.$refs[formName].model.bannerTitle,
									imgUrl: this.$refs[formName].model.imgUrl,
									indexUrl: this.$refs[formName].model.indexUrl,
									isShow: this.$refs[formName].model.isShow,
									jumpUrl: this.$refs[formName].model.jumpUrl,
									sort: this.$refs[formName].model.sort,
									id: this.id
								}).then(res=>{
									if (res.code == 200) {
										this.$message.success('修改成功');
										this.sizeForm = {
											bannerTitle: '',
											imgUrl: '',
											isShow: 1,
											sort: 1,
											appId: '',
											indexUrl: '',
											jumpUrl: ''
										}
										this.addshow = false
										this.page = 1
										this.list();
									} else {
										this.$message.error(res.msg);
									}
								}).catch(err => {
									this.$message.error(err.msg);
								})
							}
						} else {
							return false;
						}
					});
				}
			},
			//删除
			deleteList(cur, tab) {
				console.log(tab[cur])
				this.$confirm('你确定要删除【'+ tab[cur].bannerTitle +'】吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					bannerDelete(tab[cur].id).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.page = 1
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					})
				}).catch(_ => {});
			},

			//图片上传
			handleAvatarSuccess(res, file) {
				console.log(res)
				if(res.code == 200){
					this.sizeForm.imgUrl = res.data.path;
				}else{
					this.$message.error(res.msg);
				}
			},
			//图片验证
			beforeAvatarUpload(file) {
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
				// const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('Banner图片只能是 JPG 或 PNG 格式!');
				}
				// if (!isLt2M) {
				// 	this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				// return isJPG && isLt2M;
				return isJPG
			},
			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					bannerTitle: '',
					imgUrl: '',
					isShow: 1,
					sort: 1,
					appId: '',
					indexUrl: '',
					jumpUrl: ''
				}
				this.addshow = false
					
			},
			//编辑按钮点击
			updataclick(i,tab){
				console.log(tab[i])
				this.sizeForm = {
					bannerTitle: tab[i].bannerTitle,
					imgUrl: tab[i].imgUrl,
					isShow: tab[i].isShow,
					sort: tab[i].sort,
					appId: tab[i].appId,
					indexUrl: tab[i].indexUrl,
					jumpUrl: tab[i].jumpUrl
				}
				this.id = tab[i].id
				if(this.sizeForm.appId){
					this.resource = '1'
				}else{
					this.resource = '2'
				}
				this.addtype = 2
				this.addshow = true
			},
			//添加提交
			submitForm(formName) {
				if(this.addshow){
					this.$refs[formName].validate((valid) => {
						if (valid) {
							if(this.$refs[formName].model.bannerTitle == ''){
								this.$message.warning('banner标题不能为空');
							}else if(this.$refs[formName].model.imgUrl == ''){
								this.$message.warning('banner图片不能为空');
							}else if(this.resource == '1' && this.$refs[formName].model.appId == ''){
								this.$message.warning('请输入APPID');
							}else if(this.resource == '1' && this.$refs[formName].model.indexUrl == ''){
								this.$message.warning('请输入指定地址');
							}else if(this.resource == '2' && this.$refs[formName].model.jumpUrl == ''){
								this.$message.warning('请输入跳转地址');
							}else if(this.$refs[formName].model.sort.toString() == ''){
								this.$message.warning('序号不能为空');
							}else if(Number(this.$refs[formName].model.sort) < 0){
								this.$message.warning('序号不能为负数');
							}else{
								if(this.resource == '2'){
									this.$refs[formName].model.appId = '';
									this.$refs[formName].model.indexUrl = '';
								}else if(this.resource == '1'){
									this.$refs[formName].model.jumpUrl = ''
								}
								bannerAdd({
									appId: this.$refs[formName].model.appId,
									bannerTitle: this.$refs[formName].model.bannerTitle,
									imgUrl: this.$refs[formName].model.imgUrl,
									indexUrl: this.$refs[formName].model.indexUrl,
									isShow: this.$refs[formName].model.isShow,
									jumpUrl: this.$refs[formName].model.jumpUrl,
									sort: this.$refs[formName].model.sort
								}).then(res=>{
									if(res.code == 200){
										this.$message.success('添加成功');
										this.sizeForm = {
											bannerTitle: '',
											imgUrl: '',
											isShow: 1,
											sort: 1,
											appId: '',
											indexUrl: '',
											jumpUrl: ''
										}
										this.addshow = false
										this.page = 1
										this.list();
									}else{
										this.$message.error(res.msg);
									}
								}).catch(err => {
									this.$message.error(err.msg);
								})
							}
						} else {
							return false;
						}
					});
				}
			},
		}
	}
</script>

<style scoped>
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}

	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
</style>
